@import '../../color.module.scss';

.pagination {
    &List {
        &Wrapper {
            width: fit-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px auto;
            align-self: center;
            gap: 5px;
        }

        &Item {
            width: fit-content;
            padding: 0;
        }
    }

    &Button {
        height: 32px;
        min-width: 32px;
        border: 0;
        border-radius: 20px;
        background-color: transparent;
        color: $primary-blue;
        cursor: pointer;
        transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:not(:disabled):hover {
            background-color: rgba($primary-dark, 0.04);
        }

        &:disabled {
            cursor: default;
        }

        &Icon {
            color: $primary-blue;
        }

        &:disabled &Icon {
            color: $secondary-gray-one-more;
        }

        &Selected {
            @extend .paginationButton;
            width: 48px;
            background-color: $primary-blue;
            color: $primary-white;

            &:hover {
                background-color: rgba($primary-dark, 0.12);
            }
        }
    }

    &ThereMore {
        min-width: 32px;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: center;
        line-height: 1.43;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
    }
}
