@import "../../color.module.scss";

.paper {
  padding: 30px 20px;
  background-color: $primary-white;
  border-radius: 20px;
}

.catalog {
  margin-top: 1.6rem;
  padding-bottom: 12px;
}

.recipe {
  padding: 2px 6px;
  display: inline-block;
  background-color: $primary-orange;
  border-radius: 99px;
  color: $primary-white;
}

.itemButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
}

.collapse :global .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
}
