@import '../../color.module.scss';

.sideLayerList {
  overflow: hidden;
  margin-right: 3px;
  height: 100%;

  &Container {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-light;
      border-radius: 10px;
      width: 10px;
    }
}
}

.button {
  display: flex;
  color: $primary-white;
  font-weight: 600;
  justify-content: space-between;
  text-transform: none;
  padding: 10px 32px;
  font-size: 16px;

  &:hover {
    background-color: $primary-orange;
  }
}
