@import "../../color.module.scss";

.header {
  padding-bottom: 1.2rem;
  display: flex;
}

.headerMenu {
  margin-top: 16px;
  margin-left: 64px;
  flex-grow: 1;
}

.navContainer {
  height: 100%;
}

.link {
  padding: 8px 11px;
  box-sizing: content-box;
  position: relative;

  &-active {
    color: $primary-orange;
  }

  &-active::before {
    content: "";
    border-radius: 20px;
    border: 1px solid $primary-orange;
    position: absolute;
    inset: 0;
  }
}

@media screen and (max-width: 930px) {
  .navContainer {
      width: 400px;
  }
}
