@import "../../color.module.scss";

.title {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 20px;
}

.TextField {
    & > div {
        width: 100%;
        border-radius: 20px;
        font-family: Inter;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $primary-blue;
    };

    & :focus {
        background-color: transparent;
    };

    & input {
      padding: 12px 14px;
    };

    & fieldset {
      border-color: $border;
    };

    & :focus fieldset {
        border-color: $border;
        border-width: 1px;
    };

    & :hover fieldset {
        border-color: $border;
    };

    & ::spelling-error fieldset {
        border-color: red;
    }
}
