@import "../../../color.module.scss";

.link {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $primary-blue;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid $text-gray-disabled;
    
    &:hover {
        text-decoration: none;
    }
}

.button {
    height: 36px;
    border-radius: 20px;
    text-transform: none;
    padding: 6px 20px;
    font-weight: 700;
    margin-left: auto;
    box-shadow: none;
}

.wrapper {
    width: 100%;
    padding: 2px;
    padding-right: 5px;
    flex-direction: column;
    gap: 12px;
    overflow: auto;    
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-gray2 $secondary-gray;

    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-gray2;
    }
}
