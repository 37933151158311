@import "../../../color.module.scss";

.container {
  position: absolute;
  height: 100%;
  overflow-y: hidden;
}

.aclContainer {
  flex: 1;
  padding: 20px 4px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 0 20px 20px 0;
}

.radioGroup {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
}

.radioButton {
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  & span {
    color: transparent;
  }
}

.sideLoadWrapper {
  position: relative;
  right: 20px;
  top: 20%;
}
