@import '../../color.module.scss';

.filters {
  margin-left: 40px;

  button {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $transparent-white2;
  }

  &Count {
    padding: 2px 6px;
    background-color: $primary-orange;
    border-radius: 99px;
    color: $primary-white;
  }
}
