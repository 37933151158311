@import "../../color.module.scss";

.image {
  max-width: 817px;
  width: auto;
  max-height: 818px;
  height: 100%;
}

.face {
  color: $primary-white;
  font-weight: 300;
  font-size: 10rem;
  line-height: 12.1rem;
}

.somethingWentWrong {
  color: $primary-white;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 2.9rem;
  max-width: 500px;
  margin-bottom: 20px;
}

.back {
  color: $primary-white;
  font-size: 1.8rem;
  line-height: 1.6rem;
  text-transform: none;
}

.version {
  position: absolute;
  color: $primary-white;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 2.9rem;
  bottom: 5px;
  right: 10px;
}

.button {
  padding: 17px 50px;
  background-color: $primary-orange;
  border-radius: 30px;
}
