@import "../../color.module.scss";

.button {
    height: 36px;
    border-radius: 20px;
    text-transform: none;
    padding: 6px 20px;
    font-weight: 700;
    margin-left: auto;
}

%container {
    display: flex;
    align-items: center;
}

.firstContainer {
    @extend %container;

    flex: 1;
    padding: 0 20px;
    height: 60px;
    gap: 20px;
}

.userInfo {
    @extend %container;

    justify-content: flex-end;
    width: 400px;
    background-color: $primary-blue;
    height: 100%;
    padding-right: 20px;
}

.main {
    @extend %container;

    position: relative;
    justify-content: space-between;
    height: 60px;
    background-color: $project-gray;
    border-bottom: 1px solid $transparent-blue4;
    box-shadow: 0 5px 10px $transparent-blue3;
    z-index: 1000;
}

.addData {
    position: absolute;
    right: 0;
    bottom: -44px;
    height: 44px;
    width: 400px;
    background-color: $primary-blue;
    color:#fff;

    button {
        @extend %container;

        border-radius: 0;
        justify-content: space-between;
        text-transform: none;
        padding: 7px 20px;
        width: 100%;
        height: 100%;
        border-top: 1px solid $transparent-white;
        border-bottom: 1px solid $transparent-white;

        &:hover {
            background-color: $primary-orange;
        }
    }
}

.aboutProject {
    background-color: $transparent-blue;
    text-transform: none;
    border-radius: 20px;
    padding: 5px 15px;

    &:hover {
        background-color: $transparent-blue;
    }
}
