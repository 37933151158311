@import "../../color.module.scss";

.image {
    width: 38px;
    height: 38px;
    object-fit: cover;
    margin-left: 12px;
}  

.active {
    background-color: $primary-white;
}

.notActive {
    background-color: none;
}

.accordion {
    padding-top: 8px;
    width: 340px;
    background-color: $primary-white;
    border-radius: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1499;
    max-height: 95%;
    overflow-y: auto;

    &:last-of-type {
        margin: 0;
    }
}

.button {
    padding: 8px 16px;
    justify-content: space-between;

    &:active {
        background: $light-cyan;
    }

    &:first-of-type {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
}
