@import "../../color.module.scss";

.payment {
  min-height: 27.5rem;
  padding: 2rem 4rem 2rem 8rem;
  border-radius: 1rem;
  background-color: $primary-white;
  gap: 8.5rem;
  justify-content: center;

  &Grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 13.5rem 1fr;
    row-gap: .5rem;

    & p {
      color: $transparent-blue2;
    }

    & a {
      text-decoration-color: $transparent-blue2;

      &:hover {
        text-decoration-color: $transparent-blue2;
      }
    }
  }

  &Sum {
    color: $primary-orange;
  }

  &Button {
    margin-top: 20px;

    &Fail {
      background-color: $primary-orange;
    }
  }
}
