@import '../../../../../color.module.scss';

.layer {
  width: 100%;
  border-bottom: 1px solid $transparent-white;

  &:first-child {
    border-top: 1px solid $transparent-white;
  }
}

.title {
  width: 100%;
  align-items:flex-start;
  gap: 20px;
}

.settings {
  padding: 8px;
  margin-left: auto;

  svg {
    width: 20px;
    height: 20px;
  }

  &Active {
    svg {
      fill: $primary-orange;
    }
  }

  &Deactive {
    svg {
      fill: $primary-white;
    }
  }
}
