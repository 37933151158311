@import '../../color.module.scss';

.layerSettings {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.label {
    color: $transparent-white4;
}
