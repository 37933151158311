.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader-totalcount {
  position: absolute;
  width: 15px !important;
  height: 15px !important;
  margin-left: 1em;
}
