@import "../../../color.module.scss";

.bodyColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
}

.body {
  flex: 1;
  margin: 15px 5px 15px 20px;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.bodyHeader {
  margin: 30px 20px 15px;
  padding: 0 0 15px;
  border-bottom: 1px solid $light-blue;
}

.bodyFooter {
  margin: 15px 20px;
  padding: 0;
  border-top: 1px solid $light-blue;
}

.side {
  width: 30%;
  height: 100%;
  padding: 25px 15px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 20px;
}

.sideFooter {
  margin-top: auto;
}
