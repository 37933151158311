@import "../../../../../color.module.scss";

.layersHeader {
  &Radio {
    &Group {
      display: flex;
      flex-flow: row wrap;
      gap: 15px;
      width: 100px;
    }

    &Button {
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      & span {
        color: transparent;
      }
    }
  }

  &Search {
    width: 100%;

    & fieldset {
        border-color: rgba($primary-blue, .25);
    }
  }
}
