@import './color.module.scss';

%BlenderFont {
  font-family: 'Blender Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: $text-dark-blue;
}

%InterFont {
  font-family: Inter, sans-serif;
  font-style: normal;
  color: $text-dark-blue;
}

%Heading1 {
  @extend %BlenderFont;

  font-size: 4rem;
  line-height: 4.4rem;
  letter-spacing: -0.03rem;
}

%Heading2 {
  @extend %BlenderFont;

  font-size: 3rem;
  line-height: 3.2rem;
}

%Heading3 {
  @extend %InterFont;

  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.2rem;
  letter-spacing: -0.05rem;
}

%Heading4 {
  @extend %BlenderFont;

  font-size: 2.2rem;
  line-height: 2rem;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
}

%Paragraph {
  @extend %InterFont;

  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: $primary-blue;
}

%List {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style-type: none;
  counter-reset: count-int;
}

%UListItem {
  position: relative;
  padding-left: 2.6rem;

  &::before {
    content: '';
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.5rem;
    background-color: $primary-orange;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

%OListItem {
  position: relative;
  padding-left: 3.6rem;

  &::before {
    content: counter(count-int);
    counter-increment: count-int;

    @extend %InterFont;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.5rem;
    background-color: $primary-orange;
    color: $primary-white;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

%ListItemText {
  @extend %InterFont;

  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $text-dark-blue;
}

%Description {
  @extend %InterFont;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: $text-dark-blue;
}

%LinkLevel1 {
  @extend %Heading4;

  color: $primary-blue;

  &:visited {
    color: $transparent-blue5;
  }

  &:hover {
    color: $primary-orange;
  }
}

%LinkLevel2 {
  @extend %BlenderFont;

  font-size: 1.8rem;
  line-height: 2rem;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  color: $primary-blue;

  &:hover {
    color: $primary-orange;
  }
}

%LinkLevel3 {
  @extend %InterFont;

  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  text-decoration: underline;
  color: $primary-blue;
  text-transform: none;

  &:hover {
    color: $primary-orange;
  }
}

%LinkInText {
  @extend %InterFont;

  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  text-decoration: underline;
  color: $transparent-blue5;

  &:hover {
    color: $primary-orange;
  }
}

%AlertText {
  @extend %Paragraph;

  display: block;
  padding: 20px;
  border-left: 0.4rem solid $primary-orange;
  background-color: $primary-white;
}