@import "../../color.module.scss";

.cardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  background-color: $primary-white;
  border-radius: 20px;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sideBodyRight {
  display: flex;
  align-items: center;
}
