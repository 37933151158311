@import "../../color.module.scss";

.button {
    width: 100%;
    border-radius: 20px;
    text-transform: none;

    &:hover {
        background-color: $primary-orange;
    }
}

.circle {
    border: 1px solid $primary-white;
    border-radius: 50px;
    cursor: pointer;
    width: 38px;
    height: 38px;
    justify-content: center;
    align-items: center;
}

.select {
    height: 40px;
    border-radius: 20px;
    border: 1px solid $primary-white;
    color: $primary-white;

      & .select .MuiSvgIcon-root {
        color: $primary-white;
    }

      &:focus {
        border-color: transparent;
    }
}
