@import "../../../../text.module.scss";

.landingToc {
  h2,
  p,
  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
  }

  & &InnerList {
    &::before {
      top: 0;
      transform: translateY(0);
    }
  }

  ul, ol {
    @extend %List;

    margin-top: 4rem;
    padding-left: 3rem;
  }

  h1 {
    @extend %Heading1;
  }

  h2 {
    @extend %Heading2;
  }

  h3 {
    @extend %Heading3;
  }

  h4 {
    @extend %Heading4;
  }

  & > p {
    @extend %Paragraph;
  }

  ol {
    li {
      @extend %OListItem;

      & a {
        @extend %LinkLevel1;
      }

      ol, ul {
        margin-top: 15px;
        padding: 0;

        li {
          padding: 0;

          &::before {
            display: none;
          }

          & a {
            @extend %LinkLevel2;

          }

          ul, ol {
            li {
              & a {
                @extend %LinkLevel3;
              }
            }
          }
        }
      }
    }
  }

  ul {
    li {
      @extend %UListItem;

      & a {
        @extend %LinkLevel1;
      }

      ol, ul {
        margin-top: 15px;
        padding: 0;

        li {
          padding: 0;

          &::before {
            display: none;
          }

          & a {
            @extend %LinkLevel2;
          }

          ul, ol {
            li {
              & a {
                @extend %LinkLevel3;
              }
            }
          }
        }
      }
    }
  }
}