@import "../../color.module.scss";

$pageHeight: calc(100vh - 10px);

.side {
  height: 100%;
  position: fixed;
  color: $primary-white;
  max-width: 400px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &User {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 20px;
  }
}

.login-button {
  margin-left: auto;
  display: flex;
  border-radius: 99px;
  text-transform: none;
  
  &:hover {
    background-color: $primary-orange;
  }
}

@media screen and (max-width: 1200px) {
  .side {
    height: $pageHeight;
  }
}
