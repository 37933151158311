.pageTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

.boxTitle {
  margin: 12px 0 auto;
  text-align: center;
}
