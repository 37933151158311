@import '../../../../../../color.module.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $primary-white;
  padding: 20px;
  max-width: 800px;
  min-width: 410px;
  box-shadow: 4px 15px $black;
  border-radius: 10px;
  gap: 20px;

  &Title {
    font-size: 3rem;
    color: $primary-blue;
    text-align: center;
    flex: 1;
  }

  &Close {
    svg {
      fill: $primary-blue;
    }
  }

  &Question {
    color: $transparent-black;
    text-align: center;
    padding: 0 40px;
  }

  &ButtonDelete, &ButtonCancel {
    padding: 7px 25px;
    border-radius: 30px;
  }

  &ButtonDelete {
    background-color: $primary-orange;

    &:hover {
      background-color: $primary-orange;
    }
  }

  &ButtonCancel {
    border: 1px solid $primary-orange;
  }
}
