@import "../../color.module.scss";

.imageWrap {
  margin-top: 16px;
  height: 32px;
  color: $primary-white;
  flex-direction: row;
}

.image {
  height: 100%;
}
