@import '../../../../color.module.scss';

.title {
  gap: 2rem;
  overflow-y: hidden;

  & :global .MuiTypography-root {
    display: -webkit-box;
    overflow-y: hidden;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.info {
  align-items: center;
  height: 2rem;
  display: flex;
  gap: .4rem;
}

.annotation {
  margin-top: 1rem;
  font-size: 1.4rem;
  height: 6rem;
  line-height: 2rem;
  display: -webkit-box;
  overflow-y: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #{$primary-blue}50;
  & p {
    margin: 0;
  }
}