@import "../../../color.module.scss";

.listWrapper {
  flex-grow: 1;
  margin-top: 25px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-gray2 $secondary-gray;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-gray2;
  }
}

.listItemWrapper {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid $black;
}

.listItemText {
  margin-left: 10px;
  word-break: break-all;

  &Uname {
    color: #0000003d;
  }
}

.listDeleteIcon {
  margin-left: auto;
  cursor: pointer;
}

.iconClose {
  color: $primary-blue;
  cursor: pointer;
  font-size: 20px;
}
