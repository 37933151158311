$primary-orange: #FA5026;
$primary-blue: #061136;
$primary-white: #FFF;
$primary-dark: #000;

$secondary-gray: #EBEBEB;
$secondary-gray-one-more: #979DA8;
$project-gray: #F1F2F5;

$light-cyan: #F3F4F6;

$scrollbar: #C4C4C4;
$background: #131d40;
$light-gray: #E5E7EB;

$border: #06113625;

$disabled: #FFFFFF02;
$disabled-light: #fff6;

$light-blue: #0611361a;
$light-white: #ffffff4d;

$transparent-blue: #06113620;
$transparent-blue2: #06113650;
$transparent-blue3: #06113605;
$transparent-blue4: #06113610;
$transparent-blue5: #06113670;

$blue: #1D4DD4;
$black: #0000001f;
$black2: #0000003d;

$transparent-black: #00000050;

$transparent-white: #ffffff50;
$transparent-white2: #ffffff20;
$transparent-white3: #ffffff05;
$transparent-white4: #ffffff60;

$transparent-orange: #FA502640;

$text-gray-disabled: #818692;
$text-dark-blue: #071236;
$text-success: #32C665;

$side-light-blue: #2D3A5C;
$side-dark-blue: #131d40;

$divider-dark-blue: #232d4d;

$scrollbar-gray: #72798f;
$scrollbar-gray2: #72798f25;
$scrollbar-light: #6b6f7d;

$card-divider-gray: #f3f3f5;