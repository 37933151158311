.searchFilterField {
  & > div {
    margin-top: 20px;
    height: 40px;
    border-radius: 20px;
    font-family: Inter;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
  };

  & input {
    padding: 12px 24px;
    color: #666c82;
  };

  & input::placeholder {
    color: #ebebeb;
  };

  & fieldset {
    border-color: #323b59;
    border-width: 1px;
  };
}

.searchIcon {
  color: #323b59;
}
