.leaflet-right .leaflet-control > a {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px !important;
    background-color: transparent;
    cursor: pointer;
}

.leaflet-right .isochrone a {
    background-image: url("../images/isochrone.svg") !important;
    background-size: 20px;
}

.leaflet-top.leaflet-left .button-container .leaflet-buttons-control-button, .leaflet-left .leaflet-control a {
    border: none !important;
    outline: none;
    background: transparent no-repeat center;
    background-color: #fff;
}

div.leaflet-control.disabled a {
    pointer-events:none;
    cursor:default;
    background-color:#ccc !important
}

.icon-active-map {
    cursor: crosshair !important;
}

.icon-ruler {
    cursor:pointer;
    background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAN1wAADdcBQiibeAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADVSURBVCiRndIxSkMBEIThb18eSLCwV7DRIiRH8AZaWtnYeQIPINhbWIh9Kg+hCN4hEQsLUawsRBFEhLXZgEgUngvT/bOzDBuZqes0nR1dTRHRQPsH0McAw9IIlzhuC+hh7QewjEdco4dTbEMTEQOc4xBbuMcrdrGEE6ziCusVYBEXWKj4Po5q+0ZmmgljDNrMfIuIp9q2g/fM3I+IwENEbNa5w2JWIjNFxAHucFvAqEp4wRST0k1mfszam2LvG3CGSWY+z222ktrM/Pyt/rmmrvOvN/oCjRNGEaC8yE0AAAAASUVORK5CYII=') !important
}

.ruler-map {
    cursor:crosshair !important
}

.total-popup {
    width:auto !important;
    height:auto !important;
    padding-left:15px;
    margin-top:-10px !important;
    background-color:transparent
}

.total-popup-content {
    padding:1px 7px;
    background-color:#4d90fe;
    border-radius:8px;
    color:#fff;
    font-weight:bold;
    white-space:nowrap;
    text-align:center
}

.total-popup-content .poly-close {
    display:none
}

.total-popup-content .poly-close:hover {
    opacity:.7
}

.total-popup-content:hover .poly-close {
    display:inline;
    margin-left:10px;
    position:relative;
    cursor:pointer
}

.total-popup-content svg {
    width:15px;
    height:10px;
    position:relative !important;
    left:5px !important
}

.total-popup-content svg path {
    stroke:#fff;
    fill:transparent;
    stroke-linecap:round;
    stroke-width:7
}

.total-popup-content svg:hover {
    opacity:.7
}

.total-popup-content svg:active {
    opacity:.3
}

.total-popup-label {
    padding:0px;
    padding-top:10px;
    background-color:transparent;
    text-shadow:1px 1px #fff;
    color:#4d90fe;
    font-weight:bold;
    font-size:10px;
    white-space:nowrap
}

.node-label {
    top:-25px !important
}

.azimut {
    color:blue;
    text-shadow:1px 1px #fff;
    font-size:13px;
    font-weight:normal
}

.azimut-final {
    text-shadow:none;
    font-weight:bold
}

.leaflet-top.leaflet-right {
    margin-right: 4px;
    padding: 4px;
    background-color: #061136;
    border-radius: 10px;
}

.leaflet-right .leaflet-control, .multipuncture-control {
    margin-right: 0;
}

.leaflet-top.leaflet-left .button-container,.leaflet-left .leaflet-control {
    background: #fff;
}

.leaflet-top.leaflet-right .leaflet-control, .multipuncture-control {
    width: 30px;
    height: 30px;
    background: #fff3;
    border: none;
    border-radius: 10px;
}

.leaflet-right .leaflet-control.leaflet-bar {
    margin-top: 0;
}

.icon-active {
    background-color: #FA5026 !important;
    border-radius: inherit !important;
    box-shadow:inset 0 -1px 5px 2px #514d4d01;
}

.leaflet-control .icon-active {
    background-color: #FA5026 !important;
    border-radius: inherit;
}

.leaflet-top.leaflet-left .icon-ruler.icon-active {
    background-color: unset !important;
    box-shadow: inset 0 -1px 5px 2px #514d4d4f;
}

.leaflet-left .icon-ruler {
    background-image: url("../images/ruler-left.svg") !important;
}

.leaflet-right .measurement .icon-ruler {
    background-image: url("../images/expand.svg") !important;
}

.multipuncture-icon {
    background-image: url('../images/marker.svg');
    background-position: center;
    background-repeat: no-repeat;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    border-radius: 10px;
    border: 5px solid #fff;
    background-color: #fff;
}

.leaflet-top.leaflet-left {
    margin-left: 4px;
    padding: 2px;
}

.leaflet-right .isochrone {
    margin-top: 4px !important;
}

.car-icon {
    background: no-repeat center/90% buttonface url("../images/car.svg");
    filter: opacity(50%);
}

.walk-icon {
    background: no-repeat center/90% buttonface url("../images/walk.svg");
}

.leaflet-pane.leaflet-shadow-pane {
    display: none !important;
}

.leaflet-pm-toolbar .leaflet-pm-icon-marker { 
    background-image: url('../images/iconizer-marker.svg');
}

.leaflet-pm-toolbar .leaflet-pm-icon-rotate  {
    background-image: url('../images/undo.svg');
}

.leaflet-pm-toolbar .leaflet-pm-icon-cut {
    background-image: url('../images/cut.svg');
}

.leaflet-pm-toolbar .leaflet-pm-icon-delete {
    background-image: url('../images/eraser.svg');
}

.leaflet-pm-toolbar .leaflet-pm-icon-edit {
    background-image: url('../images/select.svg');
}

.leaflet-pm-toolbar .leaflet-pm-icon-drag {
    background-image: url('../images/position.svg');
}

.leaflet-tooltip {
    padding: 5px 9px;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: white;
    background-color: black;
    border-radius: 6px;
    line-height: normal;
}

.leaflet-tooltip.tooltip-left {
    left: 43px;
}

.leaflet-tooltip.tooltip-right {
    right: 43px;
}

.leaflet-tooltip::after {
    content: "";
    position: absolute;
    top: 9px;  
    border-width: 5px 7px;
    border-style: solid;
    
}

.leaflet-tooltip.tooltip-left::after {
    right: 100%;
    margin-left: -5px;
    border-color: transparent black transparent transparent;
}

.leaflet-tooltip.tooltip-right::after {
    left: 100%;
    margin-right: -5px;
    border-color: transparent transparent transparent black;
}
