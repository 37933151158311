@import "../../../color.module.scss";

.geo {
  color: $primary-white;
  background: $primary-orange;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 600;
}

.bi {
  @extend .geo;
}

.recipe {
  color: $primary-white;
  background: linear-gradient(0deg, $light-white, $light-white), $blue;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 600;
}
 