@import "../../color.module.scss";

.logoPlatform {
  margin-top: 26px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  color: $primary-blue,
}

.logoIcon {
  display: inline-flex;
  padding: 10px 12px;
  background-color: $primary-orange;
}
