@import "../../color.module.scss";

.dynamic {
  height: 100%;
  min-height: 100vh;

  &Container {
    margin-top: 12px;
  }

  &TitleAccent {
    color: $primary-orange;
  }

  &Subtitle {
    margin-top: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  &Block {
    padding: 28px;
    height: 200px;
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    border-radius: 20px;
    cursor: pointer;
  }
}

.block {
  height: 100%;

  &Project {
    & > .blockTitle, .blockStart {
      color: $primary-white;
    }
  }

  &Header {
    font-family: "Blender Pro";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: $primary-orange;
  }

  &Title {
    margin-top: 16px;
    font-family: "Blender Pro";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    color: $primary-dark;
  }

  &Bottom {
    margin-top: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $primary-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Start {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    color: $primary-dark;
    cursor: pointer;
  }
}
