@import "../../color.module.scss";

.price-container {
  height: 100%;
  display: flex;
}

.box {
  padding: 12px;
  background: $light-white;
  border-radius: 10px;
}

.button {
  width: fit-content;
  text-transform: none;
  color: $primary-white;

  &:hover {
    background-color: $primary-orange;
    color: $primary-white;
  }
}

.select {
  color: $primary-white;

  & fieldset {
    color: $primary-white;
    border-color: $primary-white;
    border-radius: 20px;
  }
}
