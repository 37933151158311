@import '../../../../color.module.scss';

.draggable_button {
  visibility: hidden;
}

.card {
  display: flex;
  align-items: stretch;
  background: 1px solid $light-white;
  border-bottom: 1px solid $side-light-blue;

  &:hover {
    background-color: $background;

    .draggable_button {
      visibility: visible;
      background-color: $side-light-blue;
    }
  }
}

.button {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.layer {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.triangle {
  margin-top: 4px;
  position: absolute;
  
  &Rotate {
    margin-top: 4px;
    position: absolute;
    transform: rotate(-90deg);
  }
}

.formulaTitle {
  gap: 15px;
  cursor: pointer;
}

.radio {
  color: $primary-orange;

  :disabled {
    color: $disabled-light;
  }
}
