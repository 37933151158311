.breadcrumbs {
  align-items: center;

  &Home {
    display: flex;
    align-items: center;
    gap: 1.1rem;
  }
}

.container {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  align-items: center;
  gap: 1.1rem;
}