@import '../../color.module.scss';

.weight {
    align-items: center;
    justify-content: space-between;
}

.slider {
    align-items: center;
    max-width: 150px;
    width: 100%;

    &Count {
        width: 30px;
        padding-left: 8px;
    }

    &Range {
        position: absolute;
        padding: 0;
        bottom: 0;
        left: 10px;
        right: 10px;
        width: auto;
    }
}

.label {
    color: $transparent-white;
}


.container {
    padding: 6px 10px;
    border-radius: 4px;
    background-color: $transparent-white3;
    position: relative;
    height: 35px;
}
