.searchSubtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
}

.account-search {
    margin: auto;
}

.box {
    max-width: 100%;
}
