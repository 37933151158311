@import "../../color.module.scss";

.link {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $primary-blue;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid $text-gray-disabled;
    
    &:hover {
        text-decoration: none;
    }
}

.button {
    display: flex;
    align-items: center;
    gap: 15px;
    
    &:hover {
        background-color: transparent,
    }

    &:disabled {
        opacity: 0.2;

        & h6 {
            color: $primary-blue;
        }

        & svg {
            stroke: $primary-blue,
        }
    }

    & svg {
        stroke: $primary-orange;
    }
}

.container {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    background-color: $primary-white;
    border-radius: 20px;

    &Hover {
        &:hover {
            outline: 2px solid $primary-orange;
            cursor: pointer;

            :global .MuiButton-root {
                & svg {
                    background-color: $primary-orange;
                    border-radius: 50%;
                    stroke: $primary-white;

                    rect {
                        stroke: $primary-orange;
                    }
                }
            }
        }
    }
}
