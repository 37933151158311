.wrapper {
  margin-top: 1.5em;
  padding-left: 3em;
  padding-right: 2em;
  max-height: 70%;
  overflow-y: auto;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
      display: none;
  };
}

.filterHeaderWrapper {
  display: flex;
  gap: 1em;
  margin-bottom: 0.75em;
}

.sideHome {
  margin-left: -30px;
  margin-bottom: 1.5em;
}

.loader {
  display: inline-block;
  position: relative;
  height: 13.5px;
}
