@import "../../../../color.module.scss";

.filtersWrap {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.checkbox {
  position: static;
  padding: 7px;

  & input {
    max-width: 34px;
  }
}

.formGroup {
  width: 100%;
}

.formControlLabel {
  display: flex;
}

.listItemButton {
  padding: 3px 0 1px 10px;
  margin: 0 0 0 -10px;
  border-radius: 10px;

  &:hover {
    background-color: $primary-orange;

    & .expandIcon {
      color: $primary-white;
    }

    & [class*="rubricTitle"] {
      color: $primary-white;
      opacity: 1;
    }
  }
}

.expandIcon {
  margin-left: auto;
  color: $primary-orange;
}

.rubricTitle {
  font-weight: 400;
  opacity: 0.5;

  &Root {
    @extend .rubricTitle;

    font-weight: 600;
    opacity: 1;
  }
}

.collapse > div > div {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 15px 0;
  border-top: 1px solid $transparent-blue;
  border-bottom: 1px solid $transparent-blue;
}
