@import '../../color.module.scss';

.listItemButton {
  justify-content: space-between;
  padding-left: 0;
}

.divider {
  background-color: $divider-dark-blue;
}

.expandIcon {
  color: $primary-orange;
}

.filtersContainer {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-gray $primary-blue;
  max-height: 380px;
  margin-top: 10px;

  & :global .MuiFormControlLabel-root.Mui-disabled {
    opacity: .3;
  }

  &::-webkit-scrollbar {
      width: 4px;
      border-radius: 3px;
  };

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-gray;
  };

  &::-webkit-scrollbar-track {
    background-color: $primary-blue;
  };
}

.collapse {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 1.5em;
}
