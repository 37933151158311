@import "../../color.module.scss";

.wrapper {
  margin-top: 20px;
}

.chipsWrapper {
  display: inline-flex;
  padding: 3px 3px 3px 15px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: $primary-orange;
  border-radius: 20px;
}

.chipsText {
  color: $primary-white;
}

.iconClose {
  margin-left: 10px;
  color: $primary-white;
  background-color: #fe7d60;
  border-radius: 20px;
  cursor: pointer;
}

.clear {
  &Button {
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  & span {
    color: transparent;
  }
  }

  &Text {
    color: $text-gray-disabled;
    border-bottom: 1px dashed $text-gray-disabled;
  }
}
