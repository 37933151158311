@import "../../color.module.scss";

.desc {
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  line-height: 2.4rem;
  letter-spacing: -0.02rem;
  color: $primary-blue;
  margin-top: 30px;

  p {
    margin: 10px 0;
  }
}

.photo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product {
  gap: 25px;
  width: 100%;
  align-items: flex-start;
  margin-top: 40px;
}

.title {
  flex-direction: row;
  gap: 36px;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 100%;
  margin-top: 40px;
  height: 327px;
  overflow: hidden;
  border-radius: 20px;
}

.button {
    width: 100%;
    height: 48px;
    color: $primary-white;
    text-transform: none;
    border-radius: 20px;
    background-color: $primary-orange;
    box-shadow: none;
}

.subscribe {
  align-self: flex-start;
  text-decoration: none;
  border-bottom: 1px solid #818692;
  cursor: pointer;
}

.collapse {
  margin-top: 20px;
  justify-content: space-between;
  display: grid;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid $text-gray-disabled;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.collapseMui :global .MuiCollapse-wrapperInner {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(295px, auto);
  justify-content: space-between;
  gap: 14px;
}

.container :global .MuiLink-root {
  &:hover {
    * {
      color: $primary-orange;
    }

    border-bottom-color: $primary-orange;
  }
}

.updateWrapper {
  margin-top: 40px;
  margin-left: 190px;
}