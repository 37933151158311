@import "../../color.module.scss";

.link {
    background: $primary-blue;
    border-radius: 10px;
    color: $primary-white;

    :hover {
        color: $primary-white;
        background: $primary-orange;
        border-radius: 10px;
    }
}

.color {
    color: $primary-white;
}
