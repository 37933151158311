@import "../../../color.module.scss";

.header {
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
}

.availableCount {
  margin-left: 1em;
  padding: 2px 6px;
  display: inline-block;
  vertical-align: middle;
  background-color: $primary-orange;
  border-radius: 99px;
  color: $primary-white;
}
