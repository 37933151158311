@import "../../../color.module.scss";

.wrapper {
  padding: 2px;
  padding-right: 5px;
  display: grid;
  overflow: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  justify-content: space-between;
  gap: 12px;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-gray2 $secondary-gray;
  width: 100%;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-gray2;
  }
}
