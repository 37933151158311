@import '../../../../../color.module.scss';

.dropdown {
  align-items: center;
  cursor: pointer;
  gap: 17px;
  flex-basis: 220px;

  &Icon {
    margin-left: auto;
  }

  &Divider {
    height: 1px;
    background-color: $transparent-white2;
  }

  &List {
    gap: 10px;
  }

  &Item {
    max-width: 220px;
    width: 100%;
    gap: 17px;
    cursor: pointer;
  }
}

.highlight {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  
  &Switch {
    padding: 14px;
  }
}

.label {
  color: $transparent-white;
}
