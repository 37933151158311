@import "../../../../color.module.scss";

.projects {
  &Container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 420px;
    justify-content: space-between;
    gap: 12px;
  }
}
