@import "../../color.module.scss";

.textField :global .MuiOutlinedInput-root {
        height: 40px;
        border-radius: 20px;
        font-family: Inter;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: $primary-white;

    & fieldset {
        border: 1px solid $primary-white;
    }

    &:hover fieldset {
        border-color: $primary-orange;
    }

    & input {
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
    }
}
