@import '../../../../../../color.module.scss';

.orderCard {
  padding: 15px;
  background-color: $primary-white;
  border-radius: 10px;
  gap: 10px;
  background-repeat: no-repeat;
  background-position: bottom 50px right;
  cursor: pointer;

  &:hover {
    outline: 2px solid $primary-orange;
  }

  &Info {
    border-bottom: 1px solid $transparent-blue2;
    padding-bottom: 10px;
  }

  &Status {
    color: $transparent-blue2;
  }

  &Item {
    gap: 5px;
    display: flex;
    justify-content: space-between;

    h6 {
      word-wrap: break-word;
      margin-left: auto;
    }
  }

  &Orders {
    flex:1;
    gap: 15px;
  }
}

.orderItem {
  color: $transparent-blue2;
}
