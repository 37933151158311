@import "../../color.module.scss";

.step {
    & .MuiSvgIcon-root {
        font-size: 2rem;
        color: $primary-white;

        & .MuiStepIcon-text {
          fill: $primary-blue;
        }
    }

    &.Mui-disabled {
    color: $primary-white;
    opacity: 0.5;
    }

    & .Mui-active {
    color: $primary-white;
    }

    & .Mui-completed {
    opacity: 0.5;
  }
}
