@import "../../color.module.scss";

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  height: calc(100vh - 60px);
  width: 100%;
}

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.bar {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translate(0, -50%);
  z-index: 999;
}

.search {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1001;
}

.loading-block {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.download {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1001;
}

.icon {
  background-color: $primary-blue;
  z-index: 1000;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  margin-right: 6px;
  right: 0;
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
}
