@import "../../../color.module.scss";

.cardContainer {
  width: 33%;
  min-width: 312px;
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 15px;
  border-radius: 10px;
  gap: 10px;
}

.bodyWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
  
.scheduleContainer {
  display: flex;
  justify-content: space-between;
}

.scheduleTextWrapper {
  display: flex;
  gap: 5px;

  &Disabled {
    @extend .scheduleTextWrapper;

    opacity: 0.3;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}

.button {
  height: 36px;
  border-radius: 20px;
  text-transform: none;
  padding: 6px 20px;
  font-weight: 700;
  margin-left: auto;
  box-shadow: none;
}

.annotation {
  font-size: 1.4rem;
  opacity: 0.5;
  height: 60px;
  line-height: 20px;
  display: -webkit-box;
  overflow-y: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  & p {
    margin: 0;
  }
}
