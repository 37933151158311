@import '../../../color.module.scss';

.card {
  flex:1;
  background-color: $primary-white;
  padding: 3rem 3rem 2rem;
  gap: 1.5rem;
  border-radius: 1rem;

  &Ico {
    justify-content: center;
  }

  &Divider {
    background-color: $transparent-blue3;
  }

  &Checkbox {
    width: 1.3rem;
    height: 1.3rem;

    & path:first-child {
      fill: $primary-white;
      stroke: $primary-orange;
    }

    & path:last-child {
      fill: $primary-orange;
    }
  }

  &ListItem {
    color: $transparent-blue2;
  }
}