.bi-container {
  height: calc(100vh - 60px);
  width: 100%;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}
