@import "../../color.module.scss";

.card {
    padding: 2rem 1.6rem;
    height: 100%;
    min-height: 342px;
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.destiny {
    height: 30px;
    line-height: 2.6rem;
    opacity: 0.6;
}

.options {
    margin-top: 3.25;
    margin-left: 0;
    flex-direction: column;
    gap: 0.8rem;
}

.itemOptions {
    display: flex;
    align-items: center;
}

.price {
    width: 100%;
    border-radius: 20px;
}
