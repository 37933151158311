@import '../../../../../../color.module.scss';

.card {
  padding: 15px;
  background-color: $background;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &Header {
    h6 { color: $primary-white; }

    border-bottom: 1px solid rgba($primary-white, 0.2);
    padding-bottom: 10px;
  }

  &Content {
    h6 { 
      color: $primary-white;
    }

    padding: 10px 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &Row {
    gap: 5px;
    display: flex;
    align-items: baseline;

    h6 {
      word-wrap: break-word;
      margin-left: auto;
      color: $primary-orange;
    }
    p { color: $primary-white; }
  }

  &SecondaryText {
    color: rgba($primary-white, 0.5);
  }

  &Actions {
    border-top: 1px solid rgba($primary-white, 0.2);
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
}

.button {
  height: 40px;
  border-radius: 20px;
  text-transform: none;
  border: 1px solid #ffffff50;
}
