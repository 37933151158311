@import "../../../../../../color.module.scss";

.projectCard {
  background-color: $primary-white;
  border-radius: 10px;
  gap: 15px;
  cursor: pointer;

  &:hover {
    outline: 2px solid $primary-orange;
  }
}
