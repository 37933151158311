@import '../../color.module.scss';

.histogram {
  align-items: flex-end;
  height: 27px;
  gap: 0.5px;

  &Item {
    flex:1 0;
    border-radius: 2px 2px 0 0;

    &Orange {
      background-color: $transparent-orange;
    }

    &Gray {
      background-color: $transparent-white2;
    }

    &Result {
      background-color: $primary-orange;
    }

    &Underline {
      position: relative;

      &:not(:last-child)::before {
        width: 260%;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: $primary-orange;
        bottom: -4px;
      }
    }
  }
}

.axis {
  justify-content: space-between;

  &Item {
    flex-grow: 0;
    flex-shrink: 1;
  }

  &Text {
    color: $transparent-white;
  }
}
