@import "../../color.module.scss";

.button {
    width: 100%;
    height: 48px;
    color: $primary-white;
    text-transform: none;
    border-radius: 99px;
    min-width: 180px;

    &:hover {
        background-color: $primary-orange;
    }

    &:disabled {
        background-color: $primary-blue;
        color: $disabled-light;
        border: 1px solid $disabled-light;
    }
}
  