@import "../../color.module.scss";

.account {
  margin-top: 34px;

  &Tabs {
    border-bottom: 1px solid $transparent-blue4;

    button {
      font-family: 'Blender Pro', sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }
  }

  & [class*="LayersBody"] [class*="cardWrapper"]{
      &:hover {
        outline: 2px solid $primary-orange;
    }
  }
}

