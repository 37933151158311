@import '../../color.module.scss';

.totalCount {
  padding: 2px 6px;
  background-color: $primary-orange;
  border-radius: 99px;
  color: $primary-white;
}

.loader {
  display: inline-block;
  position: relative;
  height: 13.5px;
}