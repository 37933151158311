@import "../../../../color.module.scss";

.description {
  color: $primary-blue;
  max-width: 310px;
  display: -webkit-box;
  overflow-y: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  margin-top: 30px;
  opacity: .5;

  ul, ol, li, p {
    margin: 0;
  }
}

.title {
  display: block;
  padding-top: 20px;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: bottom right;
  max-height: 360px;
  box-shadow: none;
}

.cardContent {
  padding: 0;

  &:last-child {
      padding: 0;
      height: 100%;
  }
}
