@import '../../../../../../color.module.scss';

.button {
  color: $primary-white;
  text-transform: none;
  border-radius: 99px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
      background-color: $primary-orange;
  }

  &:disabled {
      background-color: $primary-blue;
      color: $disabled-light;
      border: 1px solid $disabled-light;
  }
}

.darkbutton {
  color: $primary-white;
  text-transform: none;
  border-radius: 99px;
  font-size: 16px;
  font-weight: 600;

  &:hover {
      background-color: $primary-orange;
  }

  &:disabled {
      background-color: $primary-blue;
      color: $disabled-light;
      border: 1px solid $disabled-light;
  }
}
