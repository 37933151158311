@import "../../color.module.scss";

.card {
    height: 230px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    background-color: $primary-white;
    border-radius: 20px;
}

.title{
    display: inherit;
    text-transform: uppercase;
    min-height: 26px;
    max-height: 70px;
    overflow: hidden;
}

.tooltip {
    border: 1px solid;
    padding: 1px;
    background-color: $primary-white;
}

.info {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bottom {
    display: flex;
    flex-flow: column none;
}

.desc {
    max-height: 95px;
    font-size: 1rem;
    line-height: 1.4rem;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow-y: hidden;
}
