@import "../../../color.module.scss";

.wrapper {
    max-width: 450px;
}

.titleWrapper {
    max-width: 450px;
    color: $primary-white;
}

.layer {
    &Title {
        color: $primary-white;

        &Unavailable {
            @extend .layerTitle;

            opacity: 0.4;
            text-decoration: line-through;
        }
    }

    &DescriptionWrapper {
        margin-top: 8px;
        margin-left: 16px;
        opacity: 0.7;

        &Unavailable {
            @extend .layerDescriptionWrapper;

            opacity: 0.4;
        }
    }
}