@import "../../color.module.scss";

.card {
  &Wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto 1fr;
    padding: 15px;
    background-color: $primary-white;
    border-radius: 20px;
    width: 100%;
    column-gap: 20px;
    row-gap: 10px;
  }

  &Header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &Title {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  &Body {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    border-left: 1px solid $card-divider-gray;
    padding-left: 20px;

    & > div > div:last-child {
      border-top: 1px solid $card-divider-gray;
    }
  }
}
