@import "../../color.module.scss";

.tabsContainer > div > div {
  gap: 30px;
}

.tabPanel {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.container {
  height: 95vh;
  width: 95vw;
  background-color: $secondary-gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  
  &Header {
    padding: 20px 20px 0;
    height: 104px;
    width: 100%;
    border-bottom: 1px solid #d9dbe2;
  }
}

.tab {
  padding: 10px 0;
  text-transform: uppercase;
}

.closeIcon {
  cursor: pointer;
}