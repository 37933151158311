@import "../../../color.module.scss";

.Apply {
  &Button {
    min-width: 70%;
    height: 40px;
    border-radius: 20px;
    padding: 6px 20px;
    margin: 10px auto 15px;
  }

  &Text {
    text-transform: none;
    font-weight: 700;
  }
}

.Cancel {
  &Button {
    padding: 0;
    border-radius: 20px;
    margin: auto 10% 0;
    
    &:hover {
      background-color: transparent;
    }

    & span {
      color: transparent;
    }
  }

  &Text {
    color: $black2;
    border-bottom: 1px dashed $black2;
    text-transform: none;
    font-weight: 700;

    &:hover {
      color: $primary-orange;
      border-color: $primary-orange;
    }
  }
}
