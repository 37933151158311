@import "../../../../text.module.scss";
@import "../../../../color.module.scss";

$block: p;
.notLandingToc {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
  }

  & &ImageBlock {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
    background: $primary-white;
    border-radius: 1rem;

    p {
      @extend %Paragraph;
    }
  }

  & &ImageWithP {
    display: flex;
    align-items: center;
    div {
      width: 41.6rem;
      padding: 10px;
      background: $primary-white;
      border-radius: 1rem;
      flex-shrink: 0;
    }
    img {
      width: 100%;
      height: 23.1rem;
      object-fit: cover;
    }
    p {
      @extend %AlertText;
      padding: 2rem;
      background-color: $primary-white;
      transform: translateX(-5rem);
      order:1;
    }
  }

  & &InnerList {
    &::before {
      top: 0;
      transform: translateY(0);
    }
  }

  h1 {
    @extend %Heading1;
  }

  h2 {
    @extend %Heading2;
  }

  h3 {
    @extend %Heading3;
  }

  h4 {
    @extend %Heading4;
  }

  #{$block} > h1 {
    margin-bottom: 1rem;

    & + p {
      @extend %Description;
    }
  }

  #{$block} > h2, #{$block} > h3 {
    margin-bottom: 1rem;
  }

  #{$block} > h4 {
    margin-bottom: 3rem;
  }

  #{$block} {
    @extend %Paragraph;
    img {
      width: 100%;
      object-fit: contain;
    }
    & > a {
      @extend %LinkInText;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: $primary-orange;
    }
  }

  #{$block} > p + ul,
  #{$block} > p + ol {
    margin-top: 2rem;
  }

  ol, ul {
    @extend %List;
  }

  ol {
    li {
      @extend %OListItem;
      @extend %ListItemText;
      & a {
        color: $primary-orange;
        font-size: 1.4rem;
      }

      ul {
        li {
          @extend %UListItem;
        }
        li * {
          @extend %ListItemText;
        }
      }
      ol {
        li {
          @extend %UListItem;
        }
        li * {
          @extend %ListItemText;
        }
      }
    }
    li * {
      @extend %ListItemText;
    }
  }

  ul {
    li {
      @extend %UListItem;
      @extend %ListItemText;
      & a {
        color: $primary-orange;
        font-size: 1.4rem;
      }
      ul {
        li {
          @extend %UListItem;
        }
        li * {
          @extend %ListItemText;
        }
      }
      ol {
        li {
          @extend %UListItem;
        }
        li * {
          @extend %ListItemText;
        }
      }
    }
    li * {
      @extend %ListItemText;
    }
  }

  div > h4 {
    margin-bottom: 3rem;
  }

  div > ol {
    li {
      @extend %OListItem;

      & a {
        @extend %LinkLevel1;
        text-decoration: none;
      }

      ol, ul {
        margin-top: 15px;
        padding: 0;

        li {
          padding: 0;

          &::before {
            display: none;
          }

          & a {
            @extend %LinkLevel2;
          }

          ul, ol {
            li {
              & a {
                @extend %LinkLevel3;
              }
            }
          }
        }
      }
    }
  }

  div > ul {
    li {
      @extend %UListItem;

      & a {
        @extend %LinkLevel1;
      }

      ol, ul {
        margin-top: 15px;
        padding: 0;

        li {
          padding: 0;

          &::before {
            display: none;
          }

          & a {
            @extend %LinkLevel2;
          }

          ul, ol {
            li {
              & a {
                @extend %LinkLevel3;
              }
            }
          }
        }
      }
    }
  }

  p > code {
    @extend %AlertText;
  }

  div {
    width: 100%;
    background-color: transparent;
  }
}

.arrow {
  flex:0 0 18.6rem;
  &Item {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
}