@import "../../color.module.scss";

.cartIcon {
  &Button {
    width: 38px;
    height: 38px;
    border: 1px solid $primary-white;
    border-radius: 50%;
    cursor: pointer;
  }

  & :global .Mui-disabled {
    opacity: 0.4
  }
}

.badge {
  margin-right: -8px;
  display: flex;
  align-items: center;
  background-color: $primary-orange;
  width: 18px;
  height: 14px;
  position: absolute;
  justify-content: space-around;
  border-radius: 99px;
  z-index: 9;
  padding: 2px 5px;
  color: $primary-white;
}

.box {
  display: flex;
  flex-direction: row-reverse;
}
