@import '../../../../color.module.scss';

.listItem {
  &Title {
    display: -webkit-box;
    overflow-y: hidden;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &InfoText {
    color: $transparent-blue2;
  }
}

.scheduleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 15px;
  gap: 10px;
}

.value {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;

  p {
    margin: 0;

    strong, b {
      font-weight: normal;
    }
  }
}