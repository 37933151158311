@import "../../../color.module.scss";

.infoRow {
  margin-bottom: 15px;
}

.col1 {
  display: inline-block;
  width: 130px;
  color: $primary-blue;
  opacity: 0.5;
}

.col2 {
  display: inline-block;
  color: $primary-blue;
  font-weight: 600;
}
