@import "color.module.scss";

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  color: $primary-blue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

a {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary-blue;
  text-decoration: none;
}

ul, li {
  list-style-type: none;
}

@font-face {
  font-family: "Blender Pro";
  src: local("Blender Pro"),
  url("../public/fonts/BlenderPro-Bold.woff2") format("truetype");
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar;
  border-radius: 55px;
}

.image-gallery-bullet {
  height: 4px;
  width: 8px;
  transform: none;
  padding: 0;
  border-radius: 9px;
}

.image-gallery-bullets-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.image-gallery-bullets {
  bottom: 8px;
}

.cms-side {
  font-size: 14px;
}

.cms-styles a {
  color: $primary-orange;
}

.cms-side ul, .cms-side li  {
  list-style-type: auto;
}
