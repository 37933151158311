.projectSide {
  padding: 0 0 16px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  &Container {
    margin-bottom: 80px;
    height: 100vh;
    overflow: auto;
  } 
}
