@import "../../color.module.scss";
$border-radius: 20px;
$header-height: 75px;

.scrollbarStyler {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar $secondary-gray;

  &::-webkit-scrollbar {
      width: 4px;
      border-radius: 3px;
  };

  &::-webkit-scrollbar-thumb {
    color: $scrollbar;
  };

  &::-webkit-scrollbar-track {
    color: $secondary-gray;
  };
}

.block {
  @extend .scrollbarStyler;

  height: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  width: 95vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background-color: $secondary-gray;
}

.rubricsHeader {
  &Wrapper {
    height: $header-height;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $transparent-blue;
  }

  &Close {
    cursor: pointer;
  }
}

.rubricsMain {
  &Wrapper {
    height: calc(100% - $header-height);
  }
}

.rubricsBody {
  &Wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &LeftBlock {
    @extend .block;

    width: 100%;
    padding: 15px 20px;
    display: inline flex;
  }

  &CentralBlock {
    @extend .block;

    flex-grow: 1;
    padding: 15px 20px;
  }

  &FormGroup {
    width: 100%;
  }
}

.rubricsSideBody {
  &Wrapper {
    @extend .scrollbarStyler;

    height: 85%;
    margin-bottom: 5px;
    overflow-y: auto;
    scrollbar-gutter: stable;
    margin-top: 20px;
  }

  &Item {
    display: flex;
    margin: 10px 0;
    flex-direction: row;
    border-bottom: 1px solid $transparent-blue4;
    padding-bottom: 10px;
  }

  &Text {
    margin-left: 15px;
    font-weight: 500;
  }

  &Close {
    margin-left: auto;
    color: $transparent-blue2;
    cursor: pointer;

    &:hover {
      color: $primary-orange;
    }
  }
}

.scrollWrapper {
  width: 50%;
  min-width: 230px;
  padding: 5px 3px 5px 0;
  overflow: none;

  &Left {
    border-right: 1px solid $transparent-blue;
  }
}
