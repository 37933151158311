@import "../../color.module.scss";

.image-wrap {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid $primary-white;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
