@import '../../color.module.scss';

.wrapper {
  height: 100%;
  width: 100%;
  padding: 15px;
  gap: 10px;
}

.detail {
  padding: 15px;
  background-color: $primary-white;
  border-radius: 10px;
  gap: 15px;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid $transparent-blue3;
  gap: 15px;
}

.item {
  &Wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
  }

  &Info {
    width: 100%;

    &Text {
      color: $transparent-blue2;
    }
  }

  &Price {
    &Wrapper {
      width: 128px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;
    }

    &Value {
      color: $primary-orange;
    }
  }
}

.status {
  &Wrapper {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  &Title {
    width: 115px;
    color: $transparent-blue2;
  }
}

.arrow {
  &Wrapper {
    width: 100%;
    max-width: 165px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 20px;
  }

  &Link {
    display: flex;
    align-items: center;
    gap: 12px;

    h6 {
      color: $primary-dark;
    }
  }
}

.billLink {
  text-decoration: underline;
  color: $transparent-blue2;

  &:hover {
    color: $primary-orange;
  }
}
