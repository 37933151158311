@import "../../color.module.scss";

.formControlLabel {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  padding-right: 11px;
  margin-right: -100px;
  width: 100%;

  &Scroll {
    @extend .formControlLabel;

    padding-right: 5px;
  }

  & :global .Mui-checked {
    & + p {
      font-weight: 700;
      color: $primary-white;
    }
  }
}

.filtersWrap {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.checkbox {
  padding: 7px;
}

.rubricFormControlLabel {
  display: flex;
}

.rubricExpandIcon {
  margin-left: auto;
  color: $primary-orange;
}

.rubricListItemButton {
  padding: 0 0 0 10px;
  margin: 0 0 0 -10px;
}
