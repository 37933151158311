@import "../../color.module.scss";

.IconBack {
    margin-right: 14px;
    color: $primary-white;
    cursor: pointer;
}

.color {
    color: $primary-white;
}

.quantity {
    margin-left: 4px;
    padding: 4px 8px;
    display: inline-block;
    background-color: $primary-orange;
    border-radius: 99px;
}

.label {
    position: absolute;
    top: 0;
    left: 10px;
    color: $primary-orange;
    background-color: $primary-blue;
}

.sum {
    display: flex;
    justify-content: space-between;
}

.IconClose {
    color: $primary-white;
    cursor: pointer;
}

.progress {
    height: 100%;
}

.textField :global .MuiOutlinedInput-root {
    width: 100%;
    height: 40px;
    border-radius: 99px;
    font-family: Inter;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    border: 2px dashed $disabled-light;
    background: $primary-blue;
    color: $primary-white;
    margin-bottom: 35px;

    &.Mui-focused {
        opacity: 1;
        border-color: $primary-orange !important;
    }

    & input {
    padding: 12px 16px;
    }

    &.Mui-focused fieldset {
    border-width: 0;
    }

    & fieldset {
        border-width: 0;
    }
}
