@import "../../../../color.module.scss";

.ui {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-self: flex-end;
}

.included {
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    flex-shrink: 0;
    padding-left: 20px;
}

.card {
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    border-right: 1px solid $light-blue;
    padding-right: 20px;
    max-width: 50%;
}

.stack {
    width: 100%;
    background-color: $primary-white;
    border-radius: 20px ;
    padding: 20px;
    height: 100%;
}

.button {
    margin: 0;
    padding: 0;
    min-width: auto;

    &:hover {
        background-color: transparent;
    }
}
