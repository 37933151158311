@import "../../color.module.scss";

.cardWrapper {
  width: 100%;
  height: 100%;
  min-width: 312px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  overflow: hidden;
  border-radius: 10px;
  gap: 10px;
}

.title {
  border-bottom: 1px solid $card-divider-gray;
  padding-bottom: 10px;
}
