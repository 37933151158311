@import "../../color.module.scss";

.desc {
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  line-height: 2.4rem;
  letter-spacing: -0.02rem;
  color: $primary-blue;

  p {
    margin: 30px 0 0;
  }
}

.photo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.help {
  gap: 25px;
  width: 100%;
  align-items: flex-start;
  margin-top: 40px;
}

.button {
    width: 100%;
    height: 48px;
    color: $primary-white;
    text-transform: none;
    border-radius: 20px;
    box-shadow: none;

    &:hover {
      background-color: $primary-orange;
      box-shadow: none;
    }
}

.link {
  text-decoration: none;
  border-bottom: 1px solid $text-gray-disabled;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(295px, auto);
  justify-content: space-between;
  gap: 14px;
}

.collapse :global .MuiCollapse-wrapperInner {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(295px, auto);
  justify-content: space-between;
  gap: 14px;
}

.container :global .MuiLink-root {
  &:hover {
    border-bottom-color: $primary-orange;

    * {
      color: $primary-orange;
    }
  }
}

.footer {
  background-color: $primary-white;
  position: fixed;
  left: 0;
  right: 400px;
  bottom: 0;
  padding-right: 50px;
  display: none;
  border-bottom-right-radius: 10px;

  &Show {
    display: flex;
  }

  &Placeholder {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 55px;
    height: 70px;
    pointer-events: none;
  }
}
