@import '../../color.module.scss';

.popup {
  background-color: transparent;

  & :global .MuiPaper-root {
    border-radius: 10px;
    overflow: visible;

    &::before {
      content: '';
      display: block;
      position: absolute;
      border: 8px solid transparent;
      border-right: 8px solid $primary-blue;
      left: -14px;
      top: 10px;
      z-index: 1500;
    }
  }

  &Container {
    max-width: 362px;
    background-color: $primary-blue;
    border-radius: 10px;
    padding: 8px 16px 16px;
  }

  &Content {
    border-bottom: 1px solid $transparent-white2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: 10px;
  }
}