@import "../../color.module.scss";

.button {
    width: 100%;
    border-radius: 20px;
    text-transform: none;

    &:hover {
        background-color: $primary-orange;
    }
}
