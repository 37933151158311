$pageHeight: calc(100vh - 10px);

.main {
  height: 100vh;

  &Inner {
    position: relative;
    height: 100vh;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
  }
}

.mainInProject {
  height: calc(100vh - 60px);

  &Inner {
    position: relative;
    height: calc(100vh - 60px);
    overflow: hidden;
  }
}

.container {
  margin-bottom: 20px;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 1200px) {
  .main {
    height: $pageHeight;

    &Inner {
      height: $pageHeight;
    }
  }
}