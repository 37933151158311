@import '../../color.module.scss';

.create-project {
  padding-block: 12px;
  width: 100%;
  height: 40px;
  border-radius: 99px;
}

.marketplace {
  margin-top: 1.6rem;
  max-width: 1300px;

  &Breadcrumbs {
    margin-bottom: 30px;
  }
}

.button {
  height: 40px;
  background-color: $primary-blue;
  color: $primary-white;
  padding: 0 25px 0 15px;
  border-radius: 30px;
  align-items: center;
  text-transform: none;
  gap: 12px;
}

.container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 420px;
  justify-content: space-between;
  gap: 12px;
}
