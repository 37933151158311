@import "../../../../../../color.module.scss";

.projectCreate {
  align-items: center;
  padding: 15px;
  gap: 10px;
  border: 1px dashed $transparent-blue;
  border-radius: 20px;
  max-width: 312px;
  width: 100%;
  height: 60px;
  cursor: pointer;

  &:hover {
    border-color: $primary-orange;
  }

  &Img {
    max-width: 30px;
    max-height: 30px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &Cross {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
