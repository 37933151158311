$scrollDimensions: 10px;
$pageHeight: calc(100vh - $scrollDimensions);

.app {
  min-height: 100vh;
}

.layout-block {
  overflow-y: auto;
}

.side-block {
  min-width: 400px;
}


@media screen and (max-width: 1200px) {
  .app {
    overflow-y: scroll;
    min-height: $pageHeight;
    scrollbar-width: $scrollDimensions;

    &-media {
      width: 1190px;
    }
  }
  
  .layout-block {
    width: 813px;
  }
  
  .side-block {
    width: 376px;
  }

  ::-webkit-scrollbar {
    height: $scrollDimensions;
  }
}